var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            title: "Download",
            centered: "",
            "hide-footer": "",
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { close: _vm.downloadCancel },
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _c(
            "b-progress",
            { attrs: { max: 100, height: "2rem" } },
            [
              _c("b-progress-bar", {
                attrs: {
                  value: _vm.downloadPercentage,
                  label: `${_vm.downloadPercentage.toFixed(0)}%`,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-flex justify-content-center" },
            [
              _c(
                "b-button",
                {
                  staticClass: "visible mt-2",
                  attrs: { variant: "secondary" },
                  on: { click: _vm.downloadCancel },
                },
                [_vm._v(" " + _vm._s(_vm.$i18n.t("button.cancel")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }